export const solarFaqData = {
    pricing: [
        {
            "maximumLoanAmount": "N5m (Five Million Naira Only)",
            "maximumTenor": "24 months",
            "interestRate": "32%",
            "managementFee": "1% (One-off)",
            "insuranceFee": "1.5% of the asset cost (yearly)"
        }
    ],
    steps: [
        {
            "step": 1,
            "description": "On the home page enter the value of the solar you want to purchase and pay for in instalments."
        },
        {
            "step": 2,
            "description": "Input your correct email address in the field provided and upload the invoice for the equipment."
        },
        {
            "step": 3,
            "description": "At the first step, you would be required to fill in your personal information and upload documents. Documents to be uploaded are passport photograph, valid means of identification, utility bill and signature."
        },
        {
            "step": 4,
            "description": "After filling your personal information and uploading the documents, an OTP will be sent to the phone number linked to your BVN."
        },
        {
            "step": 5,
            "description": "You need to input the OTP sent to your BVN phone number to proceed. (If your BVN phone number is no longer in use, you need to visit the bank that you registered your BVN to change to the one you are using)."
        },
        {
            "step": 6,
            "description": "Once you have entered the OTP, we'll initiate a credit review, which includes generating an account statement and credit report. Fees are applicable for the account statement and credit report."
        },
        {
            "step": 7,
            "description": "If you have a clean credit report, your application will proceed to the credit stage."
        },
        {
            "step": 8,
            "description": "At the credit stage, you will see your loan summary showing your repayment date, loan amount, equity contribution, etc. (Equity contribution is an initial payment towards the solar purchase, with a minimum requirement of 30% of the asset value)."
        },
        {
            "step": 9,
            "description": "To proceed, you need to make the equity contribution available through card payment."
        },
        {
            "step": 10,
            "description": "Once the equity contribution has been made available, you will proceed to the final stage, which is the eligibility and mandate stage where you will be required to set up your account for repayment."
        },
        {
            "step": 11,
            "description": "Once your account has been linked for repayment, you will receive an email from PremiumTrust bank on how to set up a mandate on the account for repayment. Follow through the instructions for your loan to be disbursed."
        },
        {
            "step": 12,
            "description": "Once you have completed your mandate, a PremiumTrust bank account would be opened for you and your loan disbursed into the account."
        },
        {
            "step": 13,
            "description": "Upon disbursement, the loan would be moved to the solar company that you chose at the beginning of your application for processing of pick up or delivery."
        }
    ]
};



export const InsuranceFaqData = {
    pricing: [
        {
            "maximumLoanAmount": "N5m (Five Million Naira Only)",
            "maximumTenor": "6 months",
            "interestRate": "0%",
            "otherFees": "Nil"
        }
    ],
    steps: [
        {
            "step": 1,
            "description": "On the home page, enter the value of your vehicle insurance. This is the premium you want to pay."
        },
        {
            "step": 2,
            "description": "Enter your correct email address. This is the email address that would also be used during the application process."
        },
        {
            "step": 3,
            "description": "Select a partner insurance company."
        },
        {
            "step": 4,
            "description": "At the first step, you would be required to fill in your personal and vehicle information."
        },
        {
            "step": 5,
            "description": "After filling your personal and vehicle information, an OTP will be sent to the phone number linked to your BVN."
        },
        {
            "step": 6,
            "description": "You need to input the OTP sent to your BVN phone number to proceed. (If your BVN phone number is no longer in use, you need to visit the bank that you registered your BVN to change to the one you are using)."
        },
        {
            "step": 7,
            "description": "After inputting the OTP, we would conduct a credit check on you."
        },
        {
            "step": 8,
            "description": "If you have a clean credit report, your application will proceed to the credit stage."
        },
        {
            "step": 9,
            "description": "At the credit stage, you will see your loan summary showing your repayment date, loan amount, security deposit, etc. (Security deposit is a refundable amount that is equal to one month repayment and would be held throughout the repayment period that you chose)."
        },
        {
            "step": 10,
            "description": "To proceed, you need to make the security deposit available."
        },
        {
            "step": 11,
            "description": "Once the security deposit has been made available, you will proceed to the final stage, which is the eligibility and mandate stage where you will be required to set up your account for repayment."
        },
        {
            "step": 12,
            "description": "Once your account has been linked for repayment, you will receive an email from PremiumTrust Bank on how to set up a mandate on the account for repayment. Follow through the instructions for your loan to be disbursed."
        },
        {
            "step": 13,
            "description": "Once you have completed your mandate, a PremiumTrust Bank account would be opened for you and your loan disbursed into the account."
        },
        {
            "step": 14,
            "description": "Upon disbursement, the loan would be moved to the insurance company that you chose at the beginning of your application for processing of your insurance certificate."
        }
    ],
    repayment: [
        {
            "point": 1,
            "description": "Your repayment date is the 28th day of every month for the tenor you selected."
        },
        {
            "point": 2,
            "description": "You are expected to make funds available in your PremiumTrust Bank account that the loan was disbursed into before the repayment date of 28th."
        },
        {
            "point": 3,
            "description": "If you do not make funds available, it will result in a default. After the grace period of 7 days, your security deposit would be used to defray your outstanding loan repayment for the month of default."
        },
        {
            "point": 4,
            "description": "After your security deposit has been used to defray your outstanding loan repayment, your policy would also be terminated by our partner insurance company."
        }
    ]
};

