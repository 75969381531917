import axios from "axios";
import { processApi } from "../../utils/utilities";

export const initiateThirdPartyInsurance = async (_formData) => {
  try {
    const formData = new FormData();
    Object.keys(_formData).forEach((key) => {
      if (_formData[key] !== null && _formData[key] !== "") {
        formData.append(key, _formData[key]);
      }
    });
    const url = `/api/blayzInsure/api/Policy/third-party/payment`;
    const response = await processApi("post", `${url}`, formData);
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const initiateThirdPartyShop = async (request) => {
  try {
    const url = `/api/blayzShop/api/ThirdPartyLoanApplication/initiate-payment`;
    const formData = new FormData();
    Object.keys(request).forEach((key) => {
      if (request[key] !== null && request[key] !== "") {
        formData.append(key, request[key]);
      }
    });
    const response = await processApi("post", `${url}`, formData);
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const initiateThirdPartyRide = async (request) => {
  try {
    const formData = new FormData();
    Object.keys(request).forEach((key) => {
      if (request[key] !== null && request[key] !== "") {
        formData.append(key, request[key]);
      }
    });
    const url = `/api/blayzRide/api/v1/ThirdPartyLoanApplication/initiate-payment`;
    const response = await processApi("post", `${url}`, formData);
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const createCustomerInformation = async (_formData, loanReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/create-customer-information/${loanReference}`;
    const response = await processApi("post", `${url}`, _formData, {}, true);
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const comfirmOTP = async (_formData, loanReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/otp/validate/${loanReference}`;
    const response = await processApi("post", `${url}`, _formData);
    if (response) {
      console.log(response);
      return response;
    }
    // Return response to handle success in calling component
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const resendOTP = async (_formData, loanReference) => {
  try {
    console.log("baseUrl", process.env.REACT_APP_TEST_URL);
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/otp/resend/${loanReference}`;
    const response = await processApi("post", `${url}`, _formData);
    if (response) {
      console.log(response);
      return response;
    }
    // Return response to handle success in calling component
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const creditReportForm = async (_formData, loanReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/initiate/${loanReference}`;
    const response = await processApi("post", `${url}`, _formData);
    if (response) {
      console.log(response);
      return response;
    }
    // Return response to handle success in calling component
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const initiateCardPayment = async (_formData) => {
  try {
    const url = `/api/blayzPay/api/Payment/Initiate/payment`;
    const response = await processApi("post", `${url}`, _formData);
    if (response) {
      console.log(response);
      return response;
    }
    // Return response to handle success in calling component
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const validateCardPayment = async (_formData) => {
  try {
    const url = `/api/blayzPay/api/Payment/Verify/payment`;
    const response = await processApi("post", `${url}`, _formData);
    if (response) {
      console.log(response);
      return response;
    }
    // Return response to handle success in calling component
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const creditReportRequest = async (_formData, loanReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/credit-report/${loanReference}`;
    const response = await processApi("post", `${url}`, _formData);
    if (response) {
      console.log(response);
      return response;
    }
    // Return response to handle success in calling component
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};
export const getLoanSummary = async (_formData, loanReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/loan-summary/${loanReference}`;
    const response = await processApi("post", `${url}`, _formData);
    if (response) {
      console.log(response);
      return response;
    }
    // Return response to handle success in calling component
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};
export const validateSecuityDeposit = async (_formData, loanReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/validate/security-deposit/${loanReference}`;
    const response = await processApi("post", `${url}`, _formData);
    if (response) {
      console.log(response);
      return response;
    }
    // Return response to handle success in calling component
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const saveCardDetails = async (_formData, loanReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/submit/card-details/${loanReference}`;
    const response = await processApi("post", `${url}`, _formData);
    if (response) {
      console.log(response);
      return response;
    }
    // Return response to handle success in calling component
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const refData = async () => {
  try {
    const url = `/api/blayzReferenceData/crediReport/fees`;
    const response = await processApi("get", `${url}`);
    if (response) {
      console.log(response);
      return response;
    }
    // Return response to handle success in calling component
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const applicationStatus = async (loanApplicationReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/status/${loanApplicationReference}`;
    const response = await processApi("get", `${url}`);
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const getBanks = async () => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/banks`;
    const response = await processApi("get", `${url}`);
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const getVerfiedBank = async (searchParams) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/bankStatement/active-banks?`;
    if (searchParams) {
      url += `search=${searchParams}`;
    }
    const response = await processApi("get", `${url}`);
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const createMandate = async (formData, loanReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/create-mandate/${loanReference}`;
    const response = await processApi("post", `${url}`, formData);
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const verifyMandate = async (loanApplicationReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/verify-mandate/${loanApplicationReference}`;
    const response = await processApi("get", `${url}`);
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const GetMandate = async (loanApplicationReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/mandate-details/${loanApplicationReference}`;
    const response = await processApi("get", `${url}`);
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const bankStatementCredentials = async (payload, loanReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/bankStatement/credentials/${loanReference}`;
    const response = await processApi("post", `${url}`, payload);
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const requestAccountStatement = async (request, loanReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/request-statement/${loanReference}`;
    const response = await processApi("post", url, request);
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const verifyAccountStatement = async (verifyRequest, loanReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/statement/verify/${loanReference}`;
    const response = await processApi("post", `${url}`, verifyRequest);
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const analyzeAccountStatement = async (
  loanApplicationReference,
  loanReference
) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/statement/analyze/${loanReference}`;
    const response = await processApi(
      "post",
      `${url}`,
      loanApplicationReference
    );
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

// export const loanApplicationDetails = async (loanApplicationId) => {
//   try {
//     const url = `/api/blayzPay/api/ThirdPartyLoanApplication/loanApplication/admin/get-third-party-loanApplications-details?loanApplicationId=${loanApplicationId}`;
//     const response = await processApi("get", `${url}`, loanApplicationId);
//     if (response) {
//       console.log(response);
//       return response;
//     }
//   } catch (error) {
//     console.error("Error occurred while processing the form:", error);
//     return null; // Return null or any default value to handle error in calling component
//   }
// };

export const loanApplicationDetails = async (loanApplicationReference) => {
  try {
    const url = `/api/blayzPay/api/ThirdPartyLoanApplication/customer-information`;
    const response = await processApi("get", `${url}`);
    if (response) {
      console.log(response);
      // return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
}; 

export const listInsuranceCompany = async () => {
  try {
    const url = `/api/blayzInsure/api/Merchant/insurance-list`;
    const response = await processApi("get", `${url}`);
    if (response) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; // Return null or any default value to handle error in calling component
  }
};

export const getMerchantDetails = async () => {
  try {
    const url = `/api/blayzInsure/api/Merchant/get-merchant-detail-apisecret`;
    const response = await processApi("get", `${url}`);
    if (response) {
      console.log(response);
      return response;
    } 
  } catch (error) {
    console.error("Error occurred while processing the form:", error);
    return null; 
  }
}




